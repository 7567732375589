import React, { useState } from "react";
import { connect } from "react-redux";
import { countryList } from "../../../config/constants";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { countryListReactSelect } from "../../../utils/utils";
import { applyForIntroducer } from "../../../redux/actions/userActions";
import Success from "../../../components/StatusMessages/Success";
import Error from "../../../components/StatusMessages/Error";
import { NotificationManager } from "react-notifications";

const IbRequest = () => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [answers, setAnswers] = useState({});
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const questionsData = [
    {
      type: "multi-select-dropdown",
      question: "From which countries will you be referring clients from?",
      answers: countryList,
    },
    {
      type: "single-select",
      question: "How many clients are you anticipating on signing up?",
      answer1: "1-10",
      answer2: "10-50",
      answer3: "50-100",
      answer4: "100+",
    },
    {
      type: "multi-select-checkbox",
      question:
        "What marketing channels will you be using to refer/gain client sign ups?",
      answers: [
        "Social Media",
        "Friends and Family",
        "Group Chats (Whatsapp/Telegram/Discord)",
        "Professional Network",
      ],
    },
    {
      type: "multi-select-checkbox",
      question: "What Partner services are you most interested in?",
      answers: ["PAMM/Copy Trading", "Introducing Broker", "Website Traffic"],
    },
  ];

  // Handle change for single-select, multi-select, and multi-select-checkbox questions
  const handleChange = (e, questionIndex, checkboxValue) => {
    const questionType = questionsData[questionIndex].type;
    const questionKey = `question${questionIndex + 1}`;

    if (questionType === "multi-select-dropdown") {
      const selectedOptions = e.map(option => option.value);
      setAnswers({
        ...answers,
        [questionKey]: selectedOptions,
      });
    } else if (questionType === "multi-select-checkbox") {
      const currentAnswers = answers[questionKey] || [];
      const updatedAnswers = currentAnswers.includes(checkboxValue)
        ? currentAnswers.filter(item => item !== checkboxValue) // Remove if already selected
        : [...currentAnswers, checkboxValue]; // Add if not selected
      setAnswers({
        ...answers,
        [questionKey]: updatedAnswers,
      });
    } else {
      setAnswers({
        ...answers,
        [questionKey]: e.target.value,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const allAnswered = questionsData.every((question, index) => {
      const answer = answers[`question${index + 1}`];
      return question.type === "multi-select-dropdown" ||
        question.type === "multi-select-checkbox"
        ? answer && answer.length > 0
        : !!answer;
    });

    if (!allAnswered) {
      NotificationManager.error(
        "Please answer all questions before submitting.",
        "Error",
        5000
      );
      return;
    }

    const postData = questionsData.reduce((acc, question, index) => {
      const questionKey = `question${index + 1}`;
      const answerKey = `answer${index + 1}`;

      if (
        question.type === "multi-select-dropdown" ||
        question.type === "multi-select-checkbox"
      ) {
        acc[questionKey] = question.question;
        acc[answerKey] = answers[questionKey]
          ? answers[questionKey].join(", ")
          : "";
      } else {
        acc[questionKey] = question.question;
        acc[answerKey] = answers[questionKey];
      }

      return acc;
    }, {});

    setBtnDisabled(true);

    applyForIntroducer(postData)
      .then(response => {
        setBtnDisabled(false);
        if (response.data.success) {
          setStatus("success");
          setMessage("You have successfully applied for IB!");
        } else {
          setStatus("error");
          setMessage(response.data.message);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <Container>
      <Row className="justify-content-lg-center">
        <Col>
          <h1>I want to be IB</h1>
        </Col>
      </Row>

      {!status ? (
        <Row className="justify-content-lg-center">
          <Col>
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {questionsData.map((question, questionIndex) => (
                    <Form.Group key={questionIndex}>
                      <Form.Label>
                        {questionIndex + 1}. {question.question}
                      </Form.Label>

                      {question.type === "multi-select-dropdown" ? (
                        <Select
                          isMulti
                          name={`question${questionIndex + 1}`}
                          options={countryListReactSelect()}
                          value={
                            answers[`question${questionIndex + 1}`]
                              ? answers[`question${questionIndex + 1}`].map(
                                  countryCode => ({
                                    value: countryCode,
                                    label: countryList[countryCode],
                                  })
                                )
                              : []
                          }
                          onChange={selectedOptions =>
                            handleChange(selectedOptions, questionIndex)
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      ) : question.type === "multi-select-checkbox" ? (
                        question.answers.map((answer, idx) => {
                          const checkboxId = `question${questionIndex + 1}-checkbox-${idx}`;
                          return (
                            <div key={idx} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={checkboxId}
                                value={answer}
                                checked={answers[
                                  `question${questionIndex + 1}`
                                ]?.includes(answer)}
                                onChange={() =>
                                  handleChange(null, questionIndex, answer)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={checkboxId}
                              >
                                {answer}
                              </label>
                            </div>
                          );
                        })
                      ) : (
                        ["answer1", "answer2", "answer3", "answer4"].map(
                          (answerKey, index) =>
                            question[answerKey] && (
                              <Form.Check
                                key={index}
                                type="radio"
                                name={`question${questionIndex + 1}`}
                                value={question[answerKey]}
                                id={`question${questionIndex + 1}-option-${index}`}
                                label={question[answerKey]}
                                checked={
                                  answers[`question${questionIndex + 1}`] ===
                                  question[answerKey]
                                }
                                onChange={e => handleChange(e, questionIndex)}
                              />
                            )
                        )
                      )}
                    </Form.Group>
                  ))}

                  <Button
                    className="blueButton"
                    type="submit"
                    disabled={btnDisabled}
                  >
                    {btnDisabled ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Submit
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : null}

      {status === "success" ? (
        <Success>
          <p className="messageTitle success">SUCCESS!</p>
          <p className="messageText mx-auto">{message}</p>
        </Success>
      ) : null}

      {status === "error" ? (
        <Error link="/ib-request">
          <p className="messageTitle error">REQUEST FAILED!</p>
          <p className="messageText mx-auto">{message}</p>
        </Error>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(IbRequest);
