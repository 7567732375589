import axios from "axios";
import { API_URL } from "../../config/config";
import store from "../../redux/store";
import { GET_ERRORS, GET_SUCCESS } from "./types";

// Get Live Accounts
export const getLiveAccounts = (status, currency, funding) => {
  return axios.get(
    `${API_URL}/api/accounts/getLiveAccounts/${status}/${currency}/${funding}`
  );
};

// Get Demo Accounts
export const getDemoAccounts = () => {
  return axios.get(API_URL + "/api/accounts/getDemoAccounts");
};

// Get Wallet Accounts
export const getWalletAccounts = () => {
  return axios.get(API_URL + "/api/accounts/getWalletAccounts");
};

// Request a Wallet
export const requestAWallet = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestAWallet", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request An Account
export const requestAnAccount = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestAnAccount", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Update Account
export const updateAccount = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/updateAccount", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request close account
export const requestCloseAccount = accountId => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestCloseAccount", { accountId })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Check account password change status
export const checkAccountPasswordChangeStatus = accountId => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/checkAccountPasswordChangeStatus", {
      accountId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get SafeCharge Session Token
export const getSafeChargeSessionToken =
  (
    transactionType,
    accountNumber,
    accountPlatform,
    paymentMethod,
    currency,
    amount
  ) =>
  dispatch => {
    return axios
      .post(API_URL + "/api/accounts/getSafeChargeSessionToken", {
        transactionType,
        accountNumber,
        accountPlatform,
        paymentMethod,
        currency,
        amount,
      })
      .then(res =>
        dispatch({
          type: GET_SUCCESS,
          payload: res.data,
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload:
            err.response !== undefined
              ? err.response.data
              : { server: "Server error." },
        })
      );
  };

// Get ZotaPay Session Token
export const getZotaPaySessionToken =
  (
    transactionType,
    accountNumber,
    accountPlatform,
    paymentMethod,
    currency,
    amount
  ) =>
  dispatch => {
    return axios
      .post(API_URL + "/api/accounts/getZotaPaySessionToken", {
        transactionType,
        accountNumber,
        accountPlatform,
        paymentMethod,
        currency,
        amount,
      })
      .then(res =>
        dispatch({
          type: GET_SUCCESS,
          payload: res.data,
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload:
            err.response !== undefined
              ? err.response.data
              : { server: "Server error." },
        })
      );
  };

// Request Wire Transfer
export const requestWireTransfer = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestWireTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request Crypto Deposit
export const requestCryptoDeposit = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestCryptoDeposit", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request APM Transfer
export const requestApmTransfer = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestApmTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Update APM Transfer
export const updateCashierTransfer = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/updateCashierTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Update APM Withdrawal
export const updateCashierWithdrawal = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/updateCashierWithdrawal", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request Credit Card Transfer
export const requestCreditCardTransfer = cardData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestCreditCardTransfer", { cardData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request withdrawal
export const requestWithdrawal = withdrawalData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestWithdrawal", withdrawalData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Request crypto withdrawal
export const requestCryptoWithdrawal = withdrawalData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestCryptoWithdrawal", withdrawalData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Check all trading account balances
export const checkAllTradingAccountBalances = () =>
  axios.post(API_URL + "/api/accounts/checkAllTradingAccountBalances");

// Check trading account balance
export const checkTradingAccountBalance = accountNumber =>
  axios.post(API_URL + "/api/accounts/checkTradingAccountBalance", {
    accountNumber,
  });

// Log SafeCharge Redirect Link
export const logSafeChargeRedirectLink = link =>
  axios.post(API_URL + "/api/accounts/logSafeChargeRedirectLink", { link });

// Request transfer
export const requestTransfer = accountData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/requestTransfer", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get transfer history
export const getTransferHistory = () =>
  axios.get(API_URL + "/api/accounts/getTransferHistory");

// Get trading history
export const getTradingHistory = () =>
  axios.get(API_URL + "/api/accounts/getTradingHistory");

// Save Credit Card
export const addCreditCard = cardData => dispatch => {
  return axios
    .post(API_URL + "/api/users/addCreditCard", { cardData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Update Credit Card
export const updateCreditCard = cardData => dispatch => {
  return axios
    .post(API_URL + "/api/users/updateCreditCard", { cardData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get bank details
export const getBankDetails = (currency, status) =>
  axios.get(`${API_URL}/api/accounts/getBankDetails/${currency}/${status}`);

// Get ZotaPay banks
// export const getZotaPayBanks = (currency, status) =>
//   axios.get(`${API_URL}/api/accounts/getZotaPayBanks/${currency}/${status}`);

// Save bank details
export const addBankDetails = bankData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/addBankDetails", { bankData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Update bank details
export const updateBankDetails = bankData => dispatch => {
  return axios
    .post(API_URL + "/api/accounts/updateBankDetails", { bankData })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Apply For Introducer
export const applyForIntroducer = postData =>
  axios.post(API_URL + "/api/users/applyForIntroducer", postData);

// Get SumSub access token
export const getSumSubAccessToken = levelName =>
  axios.post(API_URL + "/api/users/getSumSubAccessToken", { levelName });

// Store SumSub ApplicantId
export const storeSumSubApplicantId = sumSubApplicantId =>
  axios.post(API_URL + "/api/users/storeSumSubApplicantId", {
    sumSubApplicantId,
  });

// User Started Uploading KYC Docs
export const setUserStartedUploadingKYCDocs = () =>
  axios.post(API_URL + "/api/users/setUserStartedUploadingKYCDocs");

// Save marketing code
export const marketingCode = data =>
  axios.post(API_URL + "/api/marketing/marketingCode", data);

// Freshworks
export const updateContactFreshworks = data =>
  axios.post(API_URL + "/api/freshworks/updateContact", data);

// Check approved deposits
export const checkApprovedDeposits = () =>
  axios.get(`${API_URL}/api/accounts/checkApprovedDeposits`);

// Clear success/error props
export const clearSuccessErrorProps = () => {
  store.dispatch({
    type: GET_SUCCESS,
    payload: {},
  });
  store.dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};
