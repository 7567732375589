import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import SelectAccountFrom from "./Step1";
import SelectAccountTo from "./Step2";
import SelectAmount from "./Step3";
import Confirm from "./Step4";
import Success from "../../../../components/StatusMessages/Success";
import Error from "../../../../components/StatusMessages/Error";
import { isMobileOnly } from "react-device-detect";
import { fundingMinimums } from "../../../../config/config";

const InternalTransfer = props => {
  const [state, setState] = useState({
    selectedAccountFrom: {},
    selectedAccountTo: {},
    selectedAmount: 0,
  });
  const [step, setStep] = useState("select_account_from");
  const [paymentResult, setPaymentResult] = useState("");

  const stepsHierarchy = [
    "",
    "select_account_from",
    "select_account_to",
    "select_amount",
    "confirm",
    "success",
  ];

  const handleChangeStep = step => {
    setStep(step);
  };

  const handleSelectAccount = (target, account, step) => {
    setState(prevState => {
      return { ...prevState, [target]: account };
    });
    handleChangeStep(step);
  };

  const handleUpdateAmount = amount => {
    if (amount !== "") {
      setState(prevState => {
        return { ...prevState, selectedAmount: parseFloat(amount) };
      });
    }
  };

  const handleSelectAmount = () => {
    const { email } = props.auth.user;
    const selectedAmount = parseFloat(state.selectedAmount);
    const { balance, baseCurrency } = state.selectedAccountFrom;
    const specialEmails = ["miroslav.vegh@gmail.com", "raski19+13@gmail.com"];
    const minimumAmount = specialEmails.includes(email)
      ? 1
      : fundingMinimums.minInternalTransferAmount;

    if (selectedAmount > balance) {
      alert("You cannot transfer more than your account balance!");
      return false;
    }

    if (selectedAmount >= minimumAmount) {
      handleChangeStep("confirm");
    } else {
      alert(
        `Amount too low. Minimum amount is ${baseCurrency}${minimumAmount}.`
      );
      return false;
    }
  };

  const handleSetPaymentResult = result => {
    if (result === "SUCCESS") {
      setPaymentResult(result);
    }
    if (result === "FAILED") {
      setPaymentResult(result);
    }
  };

  let StepModule;
  switch (step) {
    case "select_account_from":
      StepModule = (
        <SelectAccountFrom
          nextStep={handleChangeStep}
          selectAccount={handleSelectAccount}
        />
      );
      break;
    case "select_account_to":
      StepModule = (
        <SelectAccountTo
          selectedAccountFrom={state.selectedAccountFrom}
          nextStep={handleChangeStep}
          selectAccount={handleSelectAccount}
        />
      );
      break;
    case "select_amount":
      StepModule = (
        <SelectAmount
          selectedAccountFrom={state.selectedAccountFrom}
          selectedAccountTo={state.selectedAccountTo}
          nextStep={handleChangeStep}
          updateAmount={handleUpdateAmount}
          selectAmount={handleSelectAmount}
          transferAmount={state.selectedAmount}
        />
      );
      break;
    case "confirm":
      StepModule = (
        <Confirm
          selectedAccountFrom={state.selectedAccountFrom}
          selectedAccountTo={state.selectedAccountTo}
          transferAmount={state.selectedAmount}
          nextStep={handleChangeStep}
          setPaymentResult={handleSetPaymentResult}
        />
      );
      break;

    case "success":
      StepModule = (
        <Success>
          <p className="messageTitle success">TRANSFER CONFIRMED!</p>

          <p className="messageText mx-auto">
            Your internal transfer was confirmed and waiting for approval
          </p>
        </Success>
      );
      break;
    case "error":
      StepModule = (
        <Error link="/internal-transfer">
          <p className="messageTitle error">TRANSFER FAILED!</p>

          <p className="messageText mx-auto">
            Your transfer request failed.
            <br />
            Please try again
          </p>

          {/*<p className="messageText mx-auto">Reason: asd</p>*/}
        </Error>
      );
      break;

    default:
      StepModule = null;
      break;
  }

  return (
    <Container className="mb-5" fluid>
      <Row>
        <Col>
          <h1 className="mx-md-4">Internal Transfer</h1>
        </Col>
      </Row>
      <Row className="mx-1 mx-md-4 pt-3 greyBg topBorders">
        {!isMobileOnly || (isMobileOnly && step === "select_account_from") ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              step === "select_account_from"
                ? "active"
                : stepsHierarchy.indexOf(step) > 1
                  ? "finished"
                  : "",
            ].join(" ")}
          >
            <div className="wizardStep">
              <FaCheck className="checkmark" />
            </div>
            <span>
              <div className="wizardStepHeadline">STEP 1</div>
              <div className="wizardStepText">
                Select account to transfer from
              </div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && step === "select_account_to") ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              step === "select_account_to"
                ? "active"
                : stepsHierarchy.indexOf(step) > 1
                  ? "finished"
                  : "",
            ].join(" ")}
          >
            <div className="wizardStep">2</div>
            <span>
              <div className="wizardStepHeadline">STEP 2</div>
              <div className="wizardStepText">
                Select account to transfer to
              </div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && step === "select_amount") ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              step === "select_amount"
                ? "active"
                : stepsHierarchy.indexOf(step) > 2
                  ? "finished"
                  : "",
            ].join(" ")}
          >
            <div className="wizardStep">3</div>
            <span>
              <div className="wizardStepHeadline">STEP 3</div>
              <div className="wizardStepText">Choose the amount</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && stepsHierarchy.indexOf(step) > 3) ? (
          <Col
            className={[
              "wizardStepWrapper pl-5",
              stepsHierarchy.indexOf(step) > 3 ? "active" : "",
              paymentResult === "SUCCESS" ? "finished" : "",
              paymentResult === "FAILED" ? "failed" : "",
            ].join(" ")}
          >
            {paymentResult === "SUCCESS" ? (
              <div className="wizardStep">
                <FaCheck className="checkmark" />
              </div>
            ) : paymentResult === "FAILED" ? (
              <div className="wizardStep">X</div>
            ) : (
              <div className="wizardStep">4</div>
            )}

            <span>
              <div className="wizardStepHeadline">STEP 4</div>
              <div className="wizardStepText">Confirm</div>
            </span>
          </Col>
        ) : null}
      </Row>

      {StepModule ? (
        StepModule
      ) : (
        <Row className="mx-1 mx-md-4 greyBg bottomBorders">&nbsp;</Row>
      )}
    </Container>
  );
};

InternalTransfer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(InternalTransfer);
