import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import SelectAccount from "./Step1";
import SelectPaymentMethod from "./Step2";
// import SelectPaymentMethodAfricaSEA from "./Step2ZotapayAfricaSEA";
import SelectAmount from "./Step3";
import ConfirmBankDeposit from "./Step4BankTransfer";
// import ZotapayBankDeposit from "./Step4BankTransferZotapay";
import Step4Cashier from "./Step4Cashier";
// import Step4ZotaPay from "./Step4ZotaPay";
import Step4Crypto from "./Step4Crypto";
import Success from "../../../../components/StatusMessages/Success";
import Error from "../../../../components/StatusMessages/Error";
import { isMobileOnly } from "react-device-detect";
import { fundingMinimums } from "../../../../config/config";
// import { africanSEAZotapayCountries } from "../../../../config/constants";

const DepositFunds = props => {
  const [state, setState] = useState({
    selectedAccount: {},
    selectedPaymentMethod: {},
    selectedAmount: 0,
    selectedBank: {},
    error: "",
  });
  const [step, setStep] = useState("");
  const [paymentResult, setPaymentResult] = useState("");

  useEffect(() => {
    if (props.match.params.status) {
      if (props.match.params.status === "success") {
        setPaymentResult("SUCCESS");
        setStep("success");
      }
      if (props.match.params.status === "failed") {
        setPaymentResult("FAILED");
        setStep("error");
      }
    } else {
      setStep("select_account");
    }
  }, [props.match.params.status]);

  const stepsHierarchy = [
    "",
    "select_account",
    "select_payment_method",
    "select_amount",
    "wire",
    // "wire_zotapay",
    "cashier",
    // "zotapay",
    "cashier_nacepay",
    "success",
    "error",
  ];

  const handleChangeStep = step => {
    setStep(step);
  };

  const handleSelectAccount = account => {
    setState(prevState => {
      return { ...prevState, selectedAccount: account };
    });
    handleChangeStep("select_payment_method");
  };

  const handleSelectFundingMethod = method => {
    setState(prevState => {
      return { ...prevState, selectedPaymentMethod: method };
    });
    handleChangeStep("select_amount");
  };

  const handleUpdateAmount = amount => {
    if (amount !== "") {
      setState(prevState => {
        return { ...prevState, selectedAmount: parseFloat(amount) };
      });
    }
  };

  const handleSelectAmount = () => {
    const { email } = props.auth.user;
    const selectedAmount = parseFloat(state.selectedAmount);
    const specialEmails = fundingMinimums.excludedEmails;
    const minimumAmount = specialEmails.includes(email)
      ? 1
      : fundingMinimums.minDepositAmount;

    if (selectedAmount >= minimumAmount) {
      handleChangeStep(state.selectedPaymentMethod.type);
    } else {
      alert(
        `Amount too low. Minimum amount is ${state.selectedAccount.baseCurrency}${minimumAmount}.`
      );
      return false;
    }
  };

  const handleSetPaymentResult = result => {
    if (result === "SUCCESS") {
      setPaymentResult(result);
    }
    if (result === "FAILED") {
      setPaymentResult(result);
    }
  };

  const handleSetPaymentError = error => {
    setState(prevState => {
      return { ...prevState, error: error };
    });
  };

  let StepModule;
  switch (step) {
    case "select_account":
      StepModule = (
        <SelectAccount
          nextStep={handleChangeStep}
          selectAccount={handleSelectAccount}
          preselectAccount={props.match.params.account}
        />
      );
      break;
    case "select_payment_method":
      // if (
      //   africanSEAZotapayCountries.includes(props.auth.user.country) &&
      //   state.selectedAccount.baseCurrency === "USD"
      // )
      //   StepModule = (
      //     <>
      //       <SelectPaymentMethod
      //         nextStep={handleChangeStep}
      //         selectFundingMethod={handleSelectFundingMethod}
      //         selectedAccount={state.selectedAccount}
      //       />
      //       <SelectPaymentMethodAfricaSEA
      //         nextStep={handleChangeStep}
      //         selectFundingMethod={handleSelectFundingMethod}
      //         selectedAccount={state.selectedAccount}
      //       />
      //     </>
      //   );
      // else
      StepModule = (
        <SelectPaymentMethod
          nextStep={handleChangeStep}
          selectFundingMethod={handleSelectFundingMethod}
          selectedAccount={state.selectedAccount}
        />
      );
      break;
    case "select_amount":
      StepModule = (
        <SelectAmount
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          nextStep={handleChangeStep}
          updateAmount={handleUpdateAmount}
          selectAmount={handleSelectAmount}
          depositAmount={state.selectedAmount}
        />
      );
      break;

    case "wire":
      StepModule = (
        <ConfirmBankDeposit
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          depositAmount={state.selectedAmount}
          selectedBank={state.selectedBank}
          nextStep={handleChangeStep}
          setPaymentResult={handleSetPaymentResult}
        />
      );
      break;

    // case "wire_zotapay":
    // case "online_bank":
    // case "offline_bank":
    //   StepModule = (
    //     <ZotapayBankDeposit
    //       selectedAccount={state.selectedAccount}
    //       paymentMethod={state.selectedPaymentMethod}
    //       depositAmount={state.selectedAmount}
    //       selectedBank={state.selectedBank}
    //       nextStep={handleChangeStep}
    //       setPaymentResult={handleSetPaymentResult}
    //     />
    //   );
    //   break;

    case "cashier":
      StepModule = (
        <Step4Cashier
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          depositAmount={state.selectedAmount}
          nextStep={handleChangeStep}
          setPaymentResult={handleSetPaymentResult}
          setPaymentError={handleSetPaymentError}
        />
      );
      break;

    // case "zotapay":
    //   StepModule = (
    //     <Step4ZotaPay
    //       selectedAccount={state.selectedAccount}
    //       paymentMethod={state.selectedPaymentMethod}
    //       depositAmount={state.selectedAmount}
    //       nextStep={handleChangeStep}
    //       setPaymentResult={handleSetPaymentResult}
    //       setPaymentError={handleSetPaymentError}
    //     />
    //   );
    //   break;

    case "cashier_nacepay":
      StepModule = (
        <Step4Crypto
          selectedAccount={state.selectedAccount}
          paymentMethod={state.selectedPaymentMethod}
          depositAmount={state.selectedAmount}
          nextStep={handleChangeStep}
          setPaymentResult={handleSetPaymentResult}
          setPaymentError={handleSetPaymentError}
        />
      );
      break;

    case "success_wire":
      StepModule = (
        <Success>
          <p className="messageTitle success">DEPOSIT CONFIRMED!</p>

          <p className="messageText mx-auto">
            Your deposit request was processed successfully and waiting for
            approval
          </p>
        </Success>
      );
      break;
    case "success_crypto":
      StepModule = (
        <Success>
          <p className="messageTitle success">DEPOSIT IN PROGRESS!</p>

          <p className="messageText mx-auto">
            Your deposit is being processed and will be added to your trading
            account once the crypto network completes 6 confirmations. This may
            take anywhere from 3 to 60 minutes, depending on the network and its
            current load. You will receive a green notification, and a
            confirmation email will be sent to you once the deposit is
            successful.
            <br />
            <br /> Thank you for your patience.
          </p>
        </Success>
      );
      break;
    case "success":
      StepModule = (
        <Success>
          <p className="messageTitle success">PAYMENT SUCCESSFUL!</p>

          <p className="messageText mx-auto">
            Your payment was processed successfully and funds have been added to
            your trading account
          </p>
        </Success>
      );
      break;
    case "error":
      StepModule = (
        <Error link="/deposit-funds">
          <p className="messageTitle error">PAYMENT FAILED!</p>

          <p className="messageText mx-auto">
            Your payment was not completed.
            <br />
            Please try again
          </p>

          <p className="messageText mx-auto">
            Reason:{" "}
            {localStorage.getItem("errorMessage")
              ? localStorage.getItem("errorMessage")
              : ""}
          </p>
        </Error>
      );
      break;

    default:
      StepModule = null;
      break;
  }

  return (
    <Container className="mb-5" fluid>
      <Row>
        <Col>
          <h1 className="mx-md-4">Deposit Funds</h1>
        </Col>
      </Row>
      <Row className="mx-1 mx-md-4 pt-3 greyBg topBorders">
        {!isMobileOnly || (isMobileOnly && step === "select_account") ? (
          <Col
            className={[
              "wizardStepWrapper pl-3 pl-md-5",
              step === "select_account"
                ? "active"
                : stepsHierarchy.indexOf(step) > 1
                  ? "finished"
                  : "",
            ].join(" ")}
          >
            <div className="wizardStep">
              <FaCheck className="checkmark" />
            </div>
            <span>
              <div className="wizardStepHeadline">STEP 1</div>
              <div className="wizardStepText">Select your account</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && step === "select_payment_method") ? (
          <Col
            className={[
              "wizardStepWrapper pl-3 pl-md-5",
              step === "select_payment_method"
                ? "active"
                : stepsHierarchy.indexOf(step) > 2
                  ? "finished"
                  : "",
            ].join(" ")}
          >
            <div className="wizardStep">2</div>
            <span>
              <div className="wizardStepHeadline">STEP 2</div>
              <div className="wizardStepText">Choose funding method</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && step === "select_amount") ? (
          <Col
            className={[
              "wizardStepWrapper pl-3 pl-md-5",
              step === "select_amount"
                ? "active"
                : stepsHierarchy.indexOf(step) > 3
                  ? "finished"
                  : "",
            ].join(" ")}
          >
            <div className="wizardStep">3</div>
            <span>
              <div className="wizardStepHeadline">STEP 3</div>
              <div className="wizardStepText">Choose the amount</div>
            </span>
          </Col>
        ) : null}

        {!isMobileOnly || (isMobileOnly && stepsHierarchy.indexOf(step) > 4) ? (
          <Col
            className={[
              "wizardStepWrapper pl-3 pl-md-5",
              stepsHierarchy.indexOf(step) > 4 ? "active" : "",
              paymentResult === "SUCCESS" ? "finished" : "",
              paymentResult === "FAILED" ? "failed" : "",
            ].join(" ")}
          >
            {paymentResult === "SUCCESS" ? (
              <div className="wizardStep">
                <FaCheck className="checkmark" />
              </div>
            ) : paymentResult === "FAILED" ? (
              <div className="wizardStep">X</div>
            ) : (
              <div className="wizardStep">4</div>
            )}

            <span>
              <div className="wizardStepHeadline">STEP 4</div>
              <div className="wizardStepText">Complete payment</div>
            </span>
          </Col>
        ) : null}
      </Row>

      {StepModule ? (
        StepModule
      ) : (
        <Row className="mx-1 mx-md-4 greyBg bottomBorders">&nbsp;</Row>
      )}
    </Container>
  );
};

DepositFunds.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(DepositFunds);
